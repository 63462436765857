import React, { Component } from "react";
import { Form, Modal, Button, Table } from "react-bootstrap";
import { apiBase, winnersMap } from "../Config";

class ModalShipmentMap extends Component {
  state = {
    fetchingEnabledMods: false,
    enabledMods: [],
  };

  constructor() {
    super();
    this.initiateMap = this.initiateMap.bind(this);
    this.fetchModules = this.fetchModules.bind(this);
  }

  async fetchModules() {
    this.setState({ fetchingEnabledMods: true });
    // Fetch modules here
    try {
      const response = await fetch(apiBase + "user/enabled_mods", {
        method: "POST",
        body: new URLSearchParams({
          user_id: this.props.user_data.user_id,
          app_token: this.props.user_data.token,
        }),
      });
      if (response.status === 200) {
        const enabledModsResponse = await response.json();
        var enabledMods = [];
        enabledModsResponse.data.forEach((em) => {
          const mapped = winnersMap.find(
            (winner) => winner.conditions.subModule === em || winner.conditions.module === em
          );
          var displayName = mapped.display.name !== "[SUB_MODULE]" ? mapped.display.name : mapped.conditions.module;
          if (mapped !== undefined && enabledMods.find((emCheck) => emCheck.display === displayName) === undefined)
            enabledMods.push({
              display: displayName,
              soundFieldName: mapped.display.sound,
            });
        });
        this.setState({ enabledMods });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ fetchingEnabledMods: false });
    }
  }

  initiateMap() {
    this.fetchModules();
  }

  openNewShipment(module_name) {
    // Generating shipment name from the schema
    // const customShipmentName = module_name + "-" + this.props.user_data.team_id + "-" + Math.floor(Date.now() / 1000);

    this.props.saveShipmentMap(module_name);
  }

  closeCurrentShipment(shipment_name, module_name) {
    console.log(`Shipment Name: ${shipment_name.name}`);
    console.log(`Module Name: ${module_name}`);

    this.props.updateCloseShipment(shipment_name.id);
  }

  render() {
    return (
      <Modal
        className="modal-shipment-map"
        show={this.props.showShipmentMapModel}
        onHide={this.props.handleClose}
        dialogClassName="shipmentMapModel"
        onEnter={this.initiateMap}
      >
        <Modal.Header closeButton>
          <h3>Shipment Map | {this.props.user_data.first_name}</h3>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: "20px" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Current Shipment</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.enabledMods.map((em) => {
                  return (
                    <tr>
                      <td>{em.display}</td>
                      <td>
                        <Form.Control
                          as="select"
                          disabled={this.props.isUpdatingShipmentMap}
                          onChange={(e) => {
                            e.target.value !== "" && this.props.updateShipmentCurrent(e.target.value, em.display);
                          }}
                        >
                          {/* <option value={""}></option> */}
                          {this.props.shipmentsMap
                            .filter((sm) => sm.display_name === em.display)
                            .map((smap) => (
                              <option value={smap.name} selected={smap.is_current === 1 ? true : false}>
                                {smap.name + " [" + smap.item_count + "]"}
                              </option>
                            ))}
                          {/* {this.props.shipmentsMap.map((sm) => {
                            if (sm.display_name === em.display)
                              return (
                                
                              );
                          })} */}
                        </Form.Control>
                      </td>
                      <td>
                        <Button
                          disabled={this.props.isUpdatingShipmentMap}
                          style={{ marginLeft: "10px" }}
                          variant="success"
                          onClick={() => {
                            this.openNewShipment(em.display);
                          }}
                        >
                          Open New Shipment
                        </Button>
                        {/* {this.props.shipmentsMap.findIndex(
                          (sm) => sm.display_name === em.display && sm.is_current === 1
                        ) >= 0 ? (
                          <span></span>
                        ) : (
                          <Button
                            disabled={this.props.isUpdatingShipmentMap}
                            style={{ marginLeft: "10px" }}
                            variant="danger"
                            onClick={() => {
                              this.closeCurrentShipment(
                                this.props.shipmentsMap[
                                  this.props.shipmentsMap.findIndex(
                                    (sm) => sm.display_name === em.display && sm.is_current === 1
                                  )
                                ],
                                em.display
                              );
                            }}
                          >
                            Close Current Shipment
                          </Button>
                          <Button
                            disabled={this.props.isUpdatingShipmentMap}
                            style={{ marginLeft: "10px" }}
                            variant="success"
                            onClick={() => {
                              this.openNewShipment(em.display);
                            }}
                          >
                            Open New Shipment
                          </Button>
                        )} */}

                        {/* <Form
                          inline
                          onSubmit={(e) => {
                            this.props.saveShipmentMap(e, em.display);
                          }}
                        >
                          <Form.Control
                            disabled={this.props.isUpdatingShipmentMap}
                            type="text"
                            name="newShipmentName"
                            placeholder="Enter Shipment Name"
                          />
                          <Button
                            disabled={this.props.isUpdatingShipmentMap}
                            style={{ marginLeft: "10px" }}
                            variant="primary"
                            type="submit"
                          >
                            Add
                          </Button>
                        </Form> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalShipmentMap;

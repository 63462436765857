import { apiBase } from "../Config";

export const submitLogin = async (user_email, user_password) => {
  const response = await fetch(apiBase + "user/login", {
    method: "POST",
    body: new URLSearchParams({
      user_email: user_email,
      user_password: user_password,
    }),
  });
  const data = await response.json();
  return data;
};

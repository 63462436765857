import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import "../assets/style/home.css";
import {
  updateUserSettings,
  sendUpdateWinner,
  getTitleSearchResults,
  getASINWinner,
  formatAsinData,
  isImportantClick,
  getInfoBoxesPrices,
  addNewShipment,
  closeShipment,
  updateCurrentShipment,
  ffCombinedResponse,
  overrideLP,
  overrideSource,
  overrideShipment,
  logBookScouter,
  // addNewMPSource,
  // fetchSourceList,
  updateActiveSourceApi,
  toggleMP,
} from "../includes/HelperHome";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Table,
  Image,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import ItemInfo from "../components/ItemInfo";
import InfoBoxes from "../components/InfoBoxes";
import AsinTitle from "../components/AsinTitle";
import ModalSettings from "../components/ModalSettings";
import { apiBase, winnersMap, socketIOServerAdd } from "../Config";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import io from "socket.io-client";
import ModalShipmentMap from "../components/ModalShipmentMap";
import auth from "../Auth";
import KeepaData from "../components/KeepaData";
//import BatchSettings from "../components/BatchSettings";

const socket = io(socketIOServerAdd);

class Home extends Component {
  state = {
    textInAsinBox: "",
    asinData: [],
    fetchingAsinData: false,
    invalidAsin: false,
    triggers: [],
    triggersCat: [],
    moduleTriggerList: [],
    isValidBuy: -1,
    ValidBuySource: "",
    updatingWinner: false,
    moduleWinner: "",
    moduleWinnerColor: "",
    moduleWinnerText: "",
    subModuleWinner: "",
    isLiveLoad: 0,
    isPrintISBN: false,
    currentASIN: "",
    showSettingsModal: false,
    showAsinSelectModal: false,
    audioList: [],
    searchText: "",
    infoBoxesData: [],
    audioPlaying: false,
    printersList: [],
    multipleItems: [],
    uploadingSettings: false,
    soundApiURL: apiBase.replace("/v1", ""),
    logData: {},
    isMPSourceInput: false,
    isFirstTimeSoundPlay: true,
    lastLogID: null,
    isIBDoubleClick: false,
    isAutoPrint: false,
    loadingTriggers: true,
    currentKeyBoard: "AlphaNumeric",
    currentCat: "All",
    b64: null,
    winnerMap: null,
    sourceName: "",
    sourcePalletValue: 0,
    sourceList: [],
    isUpdatingShipmentMap: false,
    currentListingProfile: "NULL",
    isBatchSettingsOpen: true,
    shouldPlaySound: true,
    isRestricted: -1,
    user_data: auth.getUserData(),
    showShipmentMapModel: false,
    isPrinterServerConnected: false,
    shipmentsMap: [],
    listingProfiles: [],
    sounds: { nightmare: new Audio("http://curtastic.com/nightmare.mp3") },
    showUpdateSource: false,
    mpMode: false,
    totalMPScanned: 0,
    showInfoAlert: false,
    infoAlertMessage: "",
    alertMessageType: "primary",
    mpObject: {},
    newSource: "",
  };

  constructor() {
    super();
    this.selectAsin = this.selectAsin.bind(this);
    this.initiateSearch = this.initiateSearch.bind(this);
    this.fetchAsinData = this.fetchAsinData.bind(this);

    this.handleLiveToggle = this.handleLiveToggle.bind(this);
    this.handlePrintToggle = this.handlePrintToggle.bind(this);
    this.highlightAsinInputBox = this.highlightAsinInputBox.bind(this);
    this.handleAutoPrint = this.handleAutoPrint.bind(this);
    this.handleAsinSearchKeyPress = this.handleAsinSearchKeyPress.bind(this);
    this.handleOpenSettingsModal = this.handleOpenSettingsModal.bind(this);
    this.submitSaveSettings = this.submitSaveSettings.bind(this);
    this.playSound = this.playSound.bind(this);
    this.printISBNLabel = this.printISBNLabel.bind(this);
    this.updateListingProfileName = this.updateListingProfileName.bind(this);
    this.hideBatchSettings = this.hideBatchSettings.bind(this);
    this.handleOpenShipmentMap = this.handleOpenShipmentMap.bind(this);
    this.closeShipmentMap = this.closeShipmentMap.bind(this);
    this.saveShipmentMap = this.saveShipmentMap.bind(this);
    this.firstFetch = this.firstFetch.bind(this);
    this.updateShipmentCurrent = this.updateShipmentCurrent.bind(this);
    this.handleMMToggle = this.handleMMToggle.bind(this);
    this.logBSClick = this.logBSClick.bind(this);

    this.connectToPrintServer();
  }

  connectToPrintServer() {
    socket.emit("client_details", {
      user_id: this.state.user_data.user_id,
      app_token: this.state.user_data.token,
      client_type: "client",
    });

    socket.on("server_connected", () => {
      console.log("PRINT SERVER CONNECTED!");
      this.setState({ isPrinterServerConnected: true });
    });

    socket.on("handshake", () => {
      console.log(`handshake received. sending client details ...`);
      socket.emit("client_details", {
        user_id: this.state.user_data.user_id,
        app_token: this.state.user_data.token,
        client_type: "client",
      });
    });

    socket.on("siq_search", (message) => {
      console.log("Search Request Received: ", message);
      if ("asin" in message) this.initiateSearch(message.asin);
    });

    socket.on("force_reload", (message) => {
      console.log("Force Reload Request Received");
      window.location.reload(true);
    });

    socket.on("server_disconnected", () => {
      console.log("PRINT SERVER DISCONNECTED");
      this.setState({ isPrinterServerConnected: false });
    });
  }

  componentDidMount() {
    if (this.state.user_data.token === undefined || this.state.user_data.user_id === undefined) {
      auth.logout(() => {
        this.props.history.push("/");
      });
    } else {
      this.asinInput !== undefined && this.asinInput.focus();

      this.firstFetch();
      document.body.addEventListener("click", this.highlightAsinInputBox);
    }
  }

  async firstFetch() {
    // First fetch uses promises to load all required information
    console.log(`here `);
    const ffData = await ffCombinedResponse(this.state.user_data.user_id, this.state.user_data.token);

    if ("mp_source" in this.state.user_data && "scanned_value" in this.state.user_data.mp_source) {
      this.setState({ totalMPScanned: parseFloat(this.state.user_data.mp_source.scanned_value) });
    }

    for (let i = 0; i < ffData.length; i++) {
      if (ffData[i].api === "shipments_list_all" && ffData[i].status === true)
        this.setState({ shipmentsMap: ffData[i].data });
      else if (ffData[i].api === "sources_list" && ffData[i].status === true)
        this.setState({ sourceList: ffData[i].sources });
      else if (ffData[i].api === "audio_list" && ffData[i].status === true) {
        console.log(`Sound`);
        console.log(ffData[i]);
        let soundEffects = {};
        ffData[i].data.forEach((audioFile) => {
          soundEffects[audioFile.name] = new Audio(this.state.soundApiURL + audioFile.file_path);
          soundEffects[audioFile.name].addEventListener("ended", () => {
            console.log(`Audio Finished.`);
            this.setState({ audioPlaying: false });
          });
        });
        this.setState({
          audioList: ffData[i].data,
          sounds: soundEffects,
        });
      } else if (ffData[i].api === "listing_profiles" && ffData[i].status === true)
        this.setState({ listingProfiles: ffData[i].data });
    }
  }

  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  highlightAsinInputBox(event) {
    const targetElement = event.target || event.srcElement;
    if (isImportantClick(targetElement) === true) {
      this.setState({ textInAsinBox: "" });
      this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
    } else return;
  }

  /**
   * Just handles when ENTER key is pressed at ASIN INPUT BOX.
   * Basically does the same job (call this.fetchAsinData) as FormSubmit i.e. when user click 'Validate' button.
   * @param {string} target
   */
  handleAsinSearchKeyPress(target) {
    if (target.charCode === 13) {
      this.initiateSearch();
    }
  }

  /** Generate ISBN Label */
  printISBNLabel = () => {
    const userSettings = JSON.parse(this.state.user_data.settings);

    const winnerName =
      this.state.winnerMap !== null
        ? this.state.winnerMap.display.name === "[SUB_MODULE]"
          ? this.state.subModuleWinner
          : this.state.winnerMap.display.name
        : "";

    console.log(`WINNER IS: ${winnerName}`);

    socket.emit("print_label", {
      asin: this.state.asinData.meta.asin,
      searchText: this.state.searchText,
      title: "meta" in this.state.asinData && "title" in this.state.asinData.meta ? this.state.asinData.meta.title : "",
      logSource: "logSource" in userSettings ? userSettings.logSource : "",
      user_id: this.state.user_data.user_id,
      app_token: this.state.user_data.token,
      winner: winnerName,
    });
  };

  backToPage = (response) => {
    alert(response);
  };

  componentDidCatch(err, info) {
    console.log(err);
  }

  /**
   * Functions to update info on `SCAN A BARCODE`
   * 1. Scan to Update Condition, Condition Notes, Self Wear, Buy Cost  | Barcode format: UC, UCN, USW, UBC
   * 2. Scan to Update Source
   * 3. Scan to Update Winner
   */
  async updateLP(command, lpKey) {
    if ("meta" in this.state.asinData) {
      const newCondition = command.split(":")[1];
      console.log(`UPDATING CONDITION`);
      await overrideLP(
        this.state.user_data.user_id,
        this.state.user_data.token,
        this.state.asinData.meta.scan_insert_id,
        lpKey,
        newCondition.toLowerCase()
      );
    } else {
      alert(`You must scan an item first.`);
    }
  }

  async updateSource(newSource) {
    console.log(`UPDATING SOURCE`);
    const response = await overrideSource(this.state.user_data.user_id, this.state.user_data.token, newSource);
    this.setState({ showUpdateSource: true, newSource: newSource, textInAsinBox: "" }, () => {
      window.setTimeout(() => {
        this.setState({ showUpdateSource: false });
      }, 3000);
    });
    const settingsString = response.data.data[0];
    let currentCookie = this.state.user_data;
    currentCookie.settings = settingsString.settings;
    auth.setUserData(currentCookie);
    this.setState({ user_data: currentCookie });
  }

  async updateWinner(newWinner) {
    console.log(`UPDATING WINNER`);
    this.updateStaticWinner(newWinner.toLowerCase() === "reject" ? "" : newWinner);
  }

  async initiateSearch(asin, amzRestrictedMessage) {
    let searchText = asin || this.state.textInAsinBox;
    searchText = searchText.replace("*", "X");

    if (this.state.user_data.is_mm === 1 && this.state.totalMPScanned >= this.state.user_data.mp_source.pallet_value) {
      alert(`Pallet Full! Please switch to another pallet`);
    }

    // const progressBarPercentage = (
    //   (this.state.totalMPScanned * 100) /
    //   this.state.user_data.mp_source.pallet_value
    // ).toFixed(0);

    // if (progressBarPercentage >= 100) {
    //   alert(".");
    // }

    if (searchText.substring(0, 2) === "UC ") {
      await this.updateLP(searchText.split(" ")[1], "condition");
      return;
    }
    if (searchText.substring(0, 3) === "UCN ") {
      await this.updateLP(searchText.split(" ")[1], "conditionNotes");
      return;
    }
    if (searchText.substring(0, 3) === "USW ") {
      await this.updateLP(searchText.split(" ")[1], "selfWear");
      return;
    }
    if (searchText.substring(0, 3) === "UBC ") {
      await this.updateLP(searchText.split(" ")[1], "buyCost");
      return;
    }
    if (searchText.substring(0, 8) === "USOURCE ") {
      await this.updateSource(searchText.split(" ")[1]);
      return;
    }
    if (searchText.substring(0, 8) === "UWROUTE ") {
      await this.updateWinner(searchText.split(" ")[1]);
      return;
    }
    searchText = searchText.toUpperCase();

    console.log(`Text here: ${searchText}`);
    console.log(this.state.currentKeyBoard.toLowerCase());
    if (this.state.currentKeyBoard.toLowerCase() === "numeric" && searchText.length === 9) {
      console.log(`Only Numeric. Appending X`);
      searchText += "X";
    }

    this.setState({
      showAsinSelectModal: false,
      textInAsinBox: "",
      asinData: {},
      invalidAsin: false,
      searchText: searchText,
      infoBoxesData: [],
      isRestricted: undefined !== amzRestrictedMessage ? 1 : -1,
    });
    console.log(`${searchText} | ${searchText.length}`);

    if (
      searchText.length === 6 ||
      ((searchText.length === 10 || searchText.length === 12 || searchText.length === 13) &&
        (/^\d+$/.test(searchText) === true ||
          (/^\d+$/.test(searchText.substr(0, searchText.length - 1)) === true && searchText.slice(-1) === "X") ||
          (/^\d+$/.test(searchText.substr(1, searchText.length)) === true && searchText.slice(0, 1) === "B")))
    ) {
      console.log("Either a UPC or ISBN");
      this.fetchAsinData(searchText, amzRestrictedMessage);
    } else {
      this.setState({ fetchingAsinData: true });
      const multipleItems = await getTitleSearchResults(
        searchText + " " + this.state.currentCat,
        this.state.user_data.user_id,
        this.state.user_data.token
      );
      if ((multipleItems !== false) & (multipleItems.products !== undefined)) {
        if (multipleItems.products.length === 1) {
          console.log("Auto select: " + multipleItems.products[0].asin);
          this.setState({
            fetchingAsinData: false,
            asinData: { meta: { image_url: "1231dsfsd" } },
          });
          this.selectAsin(multipleItems.products[0].asin);
        } else {
          let isMatched = false;
          for (let i = 0; i < multipleItems.products.length; i++) {
            if (multipleItems.products[i].asin === this.state.searchText) {
              console.log("Auto select: " + multipleItems.products[i].asin);
              this.setState({
                fetchingAsinData: false,
                asinData: { meta: { image_url: "1231dsfsd" } },
              });
              this.selectAsin(multipleItems.products[i].asin);
              isMatched = true;
            }
          }
          if (isMatched === false) {
            this.setState({
              multipleItems: multipleItems.products,
              showAsinSelectModal: true,
              fetchingAsinData: false,
            });
          }
        }
      } else {
        this.setState({ fetchingAsinData: false, invalidAsin: true });
      }
    }
  }

  async fetchAsinData(asinToSearch, amzRestrictedMessage) {
    this.asinInput.focus();
    let soundKeys = Object.keys(this.state.sounds);

    /** iPhone SOUND Fix!! Works fine with other OS too */
    if (this.state.isFirstTimeSoundPlay === true) {
      this.setState({ isFirstTimeSoundPlay: false }, () => {
        for (let i = 0; i < soundKeys.length; i++) {
          const key = soundKeys[i];
          console.log(`WILL PLAY: ${this.state.sounds[key]}`);
          this.state.sounds[key]
            .play()
            .then((_) => {
              this.state.sounds[key].pause();
            })
            .catch((error) => {
              console.log(`Error playing sound.`);
            });
        }
      });
    }
    if (asinToSearch.length === 9) asinToSearch += "X";
    console.log("ASIN TO SEARCH: " + asinToSearch);

    this.setState({
      currentASIN: asinToSearch,
      fetchingAsinData: true,
      lastLogID: null,
      invalidAsin: false,
    });

    // lastScanInsertId will be undefined for all the searches except rejected ones
    console.log(`amzRestrictedMessage: ${amzRestrictedMessage}`);
    let asinJson = await getASINWinner(
      this.state.currentListingProfile,
      asinToSearch,
      this.state.isLiveLoad,
      this.state.user_data.token,
      amzRestrictedMessage
    );
    // asinJson = JSON.parse(JSON.stringify(asinJson));

    // Check if there's a need to open new shipment
    if (
      undefined !== asinJson &&
      "winner" in asinJson &&
      "winnerSubModule" in asinJson.winner &&
      asinJson.winner.winnerSubModule !== "" &&
      "meta" in asinJson &&
      asinJson.meta.scan_shipment_name === ""
    ) {
      const mapped = winnersMap.find(
        (winner) =>
          winner.conditions.subModule === asinJson.winner.winnerSubModule ||
          winner.conditions.module === asinJson.winner.winnerSubModule
      );

      // var displayName =
      //   undefined !== mapped
      //     ? mapped.display.name !== "[SUB_MODULE]"
      //       ? mapped.display.name
      //       : mapped.conditions.module
      //     : "BOOKSCOUTER";

      if (mapped !== undefined) {
        var displayName = mapped.display.name !== "[SUB_MODULE]" ? mapped.display.name : mapped.conditions.module;
        this.setState({
          alertMessageType: "primary",
          showInfoAlert: true,
          infoAlertMessage: `Creating New Shipment for ${displayName} ...`,
        });

        const response = await this.saveShipmentMap(displayName);
        let newShipmentName = "";
        for (let i = 0; i < response.length; i++) {
          if (response[i].display_name === displayName && response[i].is_current === 1) {
            newShipmentName = response[i].name;
            this.setState({
              alertMessageType: "success",
              showInfoAlert: true,
              infoAlertMessage: `Created new shipment for ${displayName} - ${response[i].name}`,
            });
            break;
          }
        }
        if (newShipmentName !== "") {
          window.setTimeout(() => {
            this.setState({ showInfoAlert: false });
          }, 3000);
          overrideShipment(
            this.state.user_data.id,
            this.state.user_data.token,
            asinJson.meta.scan_insert_id,
            newShipmentName
          );
        }
      }
    }
    // Check for amazon restriction (if enabled) AFTER we get asin out of search text.
    const userSettings = JSON.parse(this.state.user_data.settings);
    if (
      "isCheckRestricted" in userSettings &&
      userSettings.isCheckRestricted === "yes" &&
      typeof isRestricted === "function" &&
      undefined === amzRestrictedMessage &&
      "meta" in asinJson &&
      "asin" in asinJson.meta
    ) {
      console.log(`Checking for Amazon Restrictions for ${asinJson.meta.asin}`);
      // safe to use the function
      window.isRestricted(asinJson.meta.asin, this.state.user_data.token, (res) => {
        if (res !== false) {
          try {
            const amzResponse = JSON.parse(res);
            if (amzResponse.status === true) {
              console.log(amzResponse.data);

              const errorMessageIndex = amzResponse.data.qualificationMessages.findIndex(
                (qm) =>
                  (qm.qualificationMessage.includes("You are not approved to list this product") ||
                    qm.qualificationMessage.includes("brand") ||
                    qm.qualificationMessage.includes("you cannot list") ||
                    qm.qualificationMessage.includes("This product has other listing limitations")) &&
                  (qm.conditionList === null || qm.conditionList.toLowerCase().includes("used"))
              );
              var isRestricted = errorMessageIndex !== -1 ? 1 : 0;
              this.setState({ isRestricted }, () => {
                if (isRestricted === 1) {
                  // Step 1. Play Audio for Amazon Rejected
                  this.playSound("audioAmzRejected");

                  // Step 2. Loop infinite until we get the lastScanId
                  // let lastScanInsertId = undefined;
                  // while (true) {
                  //   console.log(`Stuck into loop`);
                  //   if ("meta" in this.state.asinData && "scan_insert_id" in this.state.asinData.meta) {
                  //     lastScanInsertId = this.state.asinData.meta.scan_insert_id;
                  //     break;
                  //   }
                  // }

                  // Step 2. Check user Settings for "onRestrictedSkipAmazon" & Hit back API with is_rejected parameter set to 1.
                  if ("onRestrictedSkipAmazon" in userSettings && userSettings.onRestrictedSkipAmazon === "yes") {
                    this.initiateSearch(
                      asinJson.meta.asin,
                      amzResponse.data.qualificationMessages[errorMessageIndex].qualificationMessage
                    );
                  }
                }
              });
            }
          } catch (err) {}
        }
      });
    }

    if (asinJson !== "" && asinJson !== false && "status" in asinJson) {
      if (asinJson.status === "success") {
        // Extract InfoBoxes data from API response.
        const infoBoxesData = getInfoBoxesPrices(asinJson, this.state.user_data.is_mm);

        // If MP Scanned is there
        if ("mp_scan_value" in asinJson.meta) {
          this.setState({ totalMPScanned: asinJson.meta.mp_scan_value });
        }
        // Read winnersMap to know what to show and play for this round
        const winnerMap = winnersMap.find((w) => {
          return w.conditions.module === asinJson.winner.winnerModule &&
            (w.conditions.subModule === "" || w.conditions.subModule === asinJson.winner.winnerSubModule) &&
            (w.conditions.forTeams.length === 0 || w.conditions.forTeams.indexOf(this.state.user_data.team_id) >= 0) &&
            (w.conditions.excludeTeams.length === 0 ||
              w.conditions.excludeTeams.indexOf(this.state.user_data.team_id) < 0)
            ? true
            : false;
        });

        this.setState({
          asinData: formatAsinData(asinJson),
          infoBoxesData: infoBoxesData,
          fetchingAsinData: false,
          isValidBuy: 1,
          winnerMap: winnerMap !== undefined ? winnerMap : null,
          moduleWinner: asinJson.winner.winnerModule,
          moduleWinnerColor: "winnerModuleColor" in asinJson.winner ? asinJson.winner.winnerModuleColor : "",
          moduleWinnerText: "winnerModuleText" in asinJson.winner ? asinJson.winner.winnerModuleText : "",
          subModuleWinner: asinJson.winner.winnerSubModule,
          lastLogID: "meta" in asinJson && "scan_insert_id" in asinJson.meta ? asinJson.meta.scan_insert_id : null,
        });

        console.log(asinJson.winner.winnerModule);

        // Auto Print if it's an ACCEPT & isAutoPrint is true
        asinJson.winner.winnerModule !== "" && this.state.isAutoPrint === true && this.printISBNLabel(asinToSearch);

        let audioString = winnerMap !== undefined ? winnerMap.display.sound : "";

        this.playSound(audioString);
      } else {
        if ("status" in asinJson && asinJson.status === false && asinJson.error === "Invalid Credentials")
          this.handleLogout();
        else {
          console.log(asinJson);
          this.setState({
            invalidAsin: true,
            isValidBuy: 0,
            fetchingAsinData: false,
          });
          this.playSound("audioNoResult");
        }
      }
    } else {
      console.log(asinJson);
      if (asinJson.error === "Invalid Credentials") this.handleLogout();
      else {
        this.setState({
          invalidAsin: true,
          isValidBuy: 0,
          fetchingAsinData: false,
        });
        this.playSound("audioNoResult");
      }
    }
    this.setState({ textInAsinBox: "" });
    if (this.asinInput !== null) this.asinInput.focus();
  }

  playSound(winner) {
    if (winner !== "audioAmzRejected" && this.state.isRestricted === 1) return false;
    if (this.state.shouldPlaySound === true) {
      console.log(`Playing sound for: ${winner}`);
      // Check to see if a valid sound setting is there
      try {
        const userSettings = JSON.parse(this.state.user_data.settings);

        if (winner in userSettings && userSettings[winner] !== "") {
          console.log("Winner: ", winner);
          const aindex = this.state.audioList.findIndex((audio) => audio.name === userSettings[winner]);
          const soundToPlay = this.state.audioList[aindex].name;
          console.log(soundToPlay);

          if (this.state.audioPlaying === true) {
            console.log(`Another audio already playing. Waiting for stop!`);
            const currentSound = this.state.sounds[soundToPlay];
            currentSound.pause();
            currentSound.currentTime = 0;
          }

          this.setState({ audioPlaying: true }, () => {
            console.log(`Playing Audio ...`);
            this.state.sounds[soundToPlay].play();
          });
        }
      } catch (err) {
        console.log("No valid sound settings: ", err);
      }
    } else console.log(`Sound disabled.`);
  }

  handleBSDoubleClick = (BSUrl) => {
    console.log("Last Click Deactivated");
    this.setState({ isIBDoubleClick: true }, () => {
      window.open(BSUrl, "_blank");
    });
  };

  updateStaticWinner = async (newWinner) => {
    this.setState({ isIBDoubleClick: false });

    /**
     * We use timer to wait and be sure if it's a single click and not double. 'cause when someone issues
     * a double click, a single click is fired along with it too. So, we wait a few millisecs before working on the`
     * single click event action. In case a double click is issues, we set isIBDoubleClick in state to TRUE.
     */
    this.staticTimer = this.updateStaticWinnerTimer = setTimeout(
      async () => {
        if (this.state.isIBDoubleClick === false || newWinner !== "BS") {
          console.log(`Switching winner to: ${newWinner} | Current moduleWinner: ${this.state.moduleWinner}`);

          let winnerSubModule = newWinner;
          let winnerModule =
            newWinner === ""
              ? ""
              : newWinner === "FBA" || newWinner === "MF"
              ? "AMAZON"
              : newWinner === "MP"
              ? "MARKETPLACE"
              : newWinner === "RMS" ||
                newWinner === "SBYB" ||
                newWinner === "BR" ||
                newWinner === "BRB" ||
                newWinner === "UBB"
              ? "WHOLESALE"
              : "BOOKSCOUTER";

          let isValidBuy = 1;
          if (
            (this.state.moduleWinner === "MARKETPLACE" || winnerSubModule === this.state.subModuleWinner) &&
            winnerModule === this.state.moduleWinner &&
            this.state.isValidBuy === 1
          ) {
            winnerSubModule = "";
            winnerModule = "";
            isValidBuy = 0;
          }

          const winnerMap = winnersMap.find((w) => {
            return w.conditions.module === winnerModule &&
              (w.conditions.subModule === "" || w.conditions.subModule === winnerSubModule) &&
              (w.conditions.forTeams.length === 0 ||
                w.conditions.forTeams.indexOf(this.state.user_data.team_id) >= 0) &&
              (w.conditions.excludeTeams.length === 0 ||
                w.conditions.excludeTeams.indexOf(this.state.user_data.team_id) < 0)
              ? true
              : false;
          });

          this.setState({ updatingWinner: true });

          const response = await sendUpdateWinner(
            this.state.user_data,
            this.state.lastLogID,
            this.state.asinData.meta.asin,
            this.state.asinData.meta.product_group,
            this.state.asinData.meta.searchText,
            this.state.asinData.meta.idType,
            winnerModule,
            winnerSubModule
          );

          this.setState({ updatingWinner: false });

          if (response.status === true) {
            this.setState({
              isValidBuy: isValidBuy,
              winnerMap: winnerMap !== undefined ? winnerMap : null,
              moduleWinner: isValidBuy === 1 ? winnerModule : "",
              subModuleWinner: isValidBuy === 1 ? winnerSubModule : "",
              moduleWinnerColor: response.data.color,
              moduleWinnerText: response.data.text,
            });
          }
        }
        clearTimeout(this.staticTimer);
      },

      200
    );
    this.setState({ textInAsinBox: "" });
    this.asinInput !== undefined && this.asinInput.focus();
  };

  // test jenkins 6

  handleLiveToggle(evt) {
    const isLive = evt.target.checked === true ? 1 : 0;
    console.log(isLive);
    this.setState({ isLiveLoad: isLive });
  }
  handlePrintToggle(evt) {
    this.setState({ isPrintISBN: evt.target.checked });
  }

  handleOpenSettingsModal() {
    console.log("Opening Settings Modal");
    this.setState({ showSettingsModal: true });
  }
  handleAutoPrint(evt) {
    this.setState({ isAutoPrint: evt.target.checked });
  }

  async submitSaveSettings(userSettingsJson) {
    this.setState({ uploadingSettings: true });

    const response = await updateUserSettings(
      this.state.user_data.user_id,
      this.state.user_data.token,
      userSettingsJson
    );

    if (response !== false && response.status === 1) {
      try {
        const settingsString = response.data.data[0];
        let currentCookie = this.state.user_data;
        currentCookie.settings = settingsString.settings;
        auth.setUserData(currentCookie);
        this.setState({ user_data: currentCookie });
      } catch (err) {
        console.log("Invalid user settings. Probably empty string");
      }
    }

    this.setState({
      showSettingsModal: false,
      uploadingSettings: false,
    });
  }

  selectAsin = (asin) => {
    console.log(`Selecting asin: ${asin}`);
    this.setState({ textInAsinBox: "", showAsinSelectModal: false }, () => this.fetchAsinData(asin));
  };

  hideBatchSettings = (profile_name) => {
    console.log(`Selected Profile Name: ${profile_name}`);
    this.setState({ isBatchSettingsOpen: false });
  };
  updateListingProfileName = (profile_name) => {
    this.setState({ currentListingProfile: profile_name });
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  handleOpenShipmentMap() {
    this.setState({ showShipmentMapModel: true });
  }

  async saveShipmentMap(displayName) {
    var user_id = this.state.user_data.user_id;
    var token = this.state.user_data.token;

    let moduleNames = [];
    winnersMap.forEach((wm) => {
      const mapArr = { mod: wm.conditions.module, subMod: wm.conditions.subModule };
      if (wm.display.name === displayName && moduleNames.indexOf(mapArr) < 0) moduleNames.push(mapArr);
    });

    this.setState({ isUpdatingShipmentMap: true });
    const response = await addNewShipment(JSON.stringify(moduleNames), displayName, user_id, token);
    if (response.status === true) {
      this.setState({ shipmentsMap: response.data });
      return response.data;
    }
    this.setState({ isUpdatingShipmentMap: false });
  }

  async closeShipmentMap(shipmentId) {
    var user_id = this.state.user_data.user_id;
    var token = this.state.user_data.token;

    this.setState({ isUpdatingShipmentMap: true });
    const response = await closeShipment(shipmentId, user_id, token);
    if (response.status === true) this.setState({ shipmentsMap: response.data });
    this.setState({ isUpdatingShipmentMap: false });
  }

  async updateShipmentCurrent(shipmentName, displayName) {
    console.log(shipmentName + " | " + displayName);
    var user_id = this.state.user_data.user_id;
    var token = this.state.user_data.token;

    this.setState({ isUpdatingShipmentMap: true });
    const response = await updateCurrentShipment(shipmentName, displayName, user_id, token);
    if (response.status === true) this.setState({ shipmentsMap: response.data });
    this.setState({ isUpdatingShipmentMap: false });
  }

  // async saveNewMPSource() {
  //   const response = await addNewMPSource(
  //     this.state.sourceName,
  //     this.state.sourcePalletValue,
  //     this.state.user_data.user_id,
  //     this.state.user_data.token
  //   );
  // }

  async logBSClick(bsPrice) {
    const response = await logBookScouter(
      this.state.asinData.meta.scan_insert_id,
      this.state.asinData.meta.asin,
      bsPrice,
      this.state.user_data.user_id,
      this.state.user_data.token
    );
    console.log(response);
  }

  async handleMMToggle(e) {
    // First clear current search
    this.setState({
      showAsinSelectModal: false,
      textInAsinBox: "",
      asinData: {},
      invalidAsin: false,
      searchText: "",
      infoBoxesData: [],
      isRestricted: -1,
    });

    // Then do the rest
    const mpSource = await toggleMP(this.state.user_data.user_id, this.state.user_data.token);
    if (undefined !== mpSource && "current_status" in mpSource) {
      console.log(mpSource);
      let user_data = { ...this.state.user_data };
      user_data.is_mm = mpSource.current_status;

      if ("source_name" in mpSource) {
        user_data.mp_source.name = mpSource.source_name;
        user_data.mp_source.pallet_value = mpSource.pallet_value;
        this.setState({ totalMPScanned: mpSource.scanned_value });
      }
      this.setState({ user_data });
    }

    // Finally, highlight the asin input box
    this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
  }

  async updateActiveSource(newSource) {
    console.log(`Updating Current Pallet Source to: ${newSource}`);
    const mpSource = await updateActiveSourceApi(this.state.user_data.user_id, this.state.user_data.token, newSource);

    if (undefined !== mpSource && "source_name" in mpSource) {
      console.log(mpSource);
      let user_data = { ...this.state.user_data };
      // user_data.is_mm = mpSource.current_status;
      user_data.mp_source.name = mpSource.source_name;
      user_data.mp_source.pallet_value = mpSource.pallet_value;
      // this.setState({ totalMPScanned: mpSource.scanned_value });
      this.setState({ user_data, totalMPScanned: mpSource.scanned_value });
    }

    this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
  }

  render() {
    const progressBarPercentage = (
      (this.state.totalMPScanned * 100) /
      this.state.user_data.mp_source.pallet_value
    ).toFixed(0);

    // if (progressBarPercentage >= 100) {
    //   alert("Pallet Full! Please switch to another pallet.");
    // }
    const componentToReturn =
      this.state.user_data.token !== undefined ? (
        <React.Fragment>
          <ModalSettings
            user_data={this.state.user_data}
            printersList={this.state.printersList}
            isMM={"is_mm" in this.state.user_data ? this.state.user_data.is_mm : ""}
            userSettings={
              this.state.user_data.settings !== "" &&
              this.state.user_data.settings !== undefined &&
              this.state.user_data.settings !== null
                ? JSON.parse(this.state.user_data.settings)
                : {}
            }
            audioList={this.state.audioList}
            uploadingSettings={this.state.uploadingSettings}
            submitSaveSettings={this.submitSaveSettings}
            showSettingsModal={this.state.showSettingsModal}
            handleClose={() => this.setState({ showSettingsModal: false })}
          />

          <ModalShipmentMap
            isUpdatingShipmentMap={this.state.isUpdatingShipmentMap}
            shipmentsMap={this.state.shipmentsMap}
            updateShipmentCurrent={this.updateShipmentCurrent}
            saveShipmentMap={this.saveShipmentMap}
            updateCloseShipment={this.closeShipmentMap}
            user_data={this.state.user_data}
            showShipmentMapModel={this.state.showShipmentMapModel}
            handleClose={() => this.setState({ showShipmentMapModel: false })}
          />

          {/* <ModalSelectAsin
            selectAsin={this.selectAsin}
            showAsinSelectModal={this.state.showAsinSelectModal}
            multipleItems={this.state.multipleItems}
            handleClose={() => this.setState({ showAsinSelectModal: false })}
          /> */}

          <TopMenu
            history={this.props.history}
            handleMMToggle={this.handleMMToggle}
            handleOpenShipmentMap={this.handleOpenShipmentMap}
            handleAutoPrint={this.handleAutoPrint}
            shouldPlaySound={this.state.shouldPlaySound}
            handleSoundToggle={(e) => this.setState({ shouldPlaySound: e.target.checked })}
            isAutoPrint={this.state.isAutoPrint}
            isPrinterServerConnected={this.state.isPrinterServerConnected}
            // QZPrinter={this.state.QZPrinter}
            handleOpenSettingsModal={this.handleOpenSettingsModal}
            handleLiveToggle={this.handleLiveToggle}
            handlePrintToggle={this.handlePrintToggle}
            listingProfiles={this.state.listingProfiles}
            isMM={"is_mm" in this.state.user_data ? this.state.user_data.is_mm : null}
            userSettings={
              this.state.user_data.settings !== "" &&
              this.state.user_data.settings !== undefined &&
              this.state.user_data.settings !== null
                ? JSON.parse(this.state.user_data.settings)
                : {}
            }
          />

          {this.state.showUpdateSource && (
            <Alert variant="success" dismissible onClose={() => this.setState({ showUpdateSource: false })}>
              Source updated successfully to <b>{this.state.newSource}</b>!
            </Alert>
          )}

          {this.state.showInfoAlert && (
            <Alert
              variant={this.state.alertMessageType}
              dismissible
              onClose={() => this.setState({ showInfoAlert: false })}
            >
              {this.state.infoAlertMessage}
            </Alert>
          )}

          <Container fluid={true}>
            {/* {this.state.isBatchSettingsOpen === false ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ color: "#000" }}
                  variant="link"
                  onClick={() => this.setState({ isBatchSettingsOpen: true })}
                >
                  <small>
                    Current Listing Profile: <strong>{this.state.currentListingProfile}</strong>. Click to show Listing
                    Profile Settings
                  </small>
                </Button>
              </div>
            ) : (
              <BatchSettings
                updateListingProfileName={this.updateListingProfileName}
                hideBatchSettings={this.hideBatchSettings}
                user_data={this.state.user_data}
              />

            )} */}

            <div id="containerIG">
              <InputGroup id="ig_asin">
                <InputGroup.Prepend className="ig_cat_dd">
                  <Dropdown
                    options={["All", "Books", "Music", "Videos", "Video Games"]}
                    onChange={(cat) => {
                      this.setState({ currentCat: cat.value });
                    }}
                    value={this.state.currentCat}
                    placeholder="Select an option"
                  />
                  <div className="keyboard-selection" style={{ width: "40px" }}></div>
                  <Dropdown
                    className="keyboard-selection"
                    options={["AlphaNumeric", "Numeric"]}
                    onChange={(keyboardVal) => {
                      this.setState({ currentKeyBoard: keyboardVal.value }, () => {
                        console.log(`Chanding current keyboard: ${this.state.currentKeyBoard}`);
                      });
                    }}
                    value={this.state.currentKeyBoard}
                  />
                </InputGroup.Prepend>

                <FormControl
                  ref={(input) => {
                    this.asinInput = input;
                  }}
                  value={this.state.textInAsinBox}
                  onChange={(e) => {
                    this.setState({ textInAsinBox: e.target.value });
                  }}
                  autoComplete="off"
                  type={this.state.currentKeyBoard.toLowerCase().includes("alpha") ? "text" : "number"}
                  onKeyPress={this.handleAsinSearchKeyPress}
                  id="asinInputBox"
                  placeholder="Search by ASIN, UPC or Title"
                  aria-describedby="basic-addon1"
                />
                <InputGroup.Append className={"btn_asin_search"}>
                  <Button
                    onClick={() => this.initiateSearch()}
                    className={
                      "button-search " +
                      (this.state.textInAsinBox === "" &&
                      this.state.currentASIN !== "" &&
                      this.state.fetchingAsinData === false
                        ? "showAsinInButton"
                        : "")
                    }
                    disabled={this.state.textInAsinBox === "" || this.state.fetchingAsinData === true}
                  >
                    {this.state.fetchingAsinData === true ? (
                      <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <span>Search</span>
                    )}
                  </Button>
                </InputGroup.Append>
              </InputGroup>

              {this.state.isRestricted === 1 && (
                <div
                  style={{
                    background: "#E1422E",
                    padding: "7px",
                    width: "100%",
                    marginTop: "5px",
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  AMAZON RESTRICTED
                </div>
              )}

              {this.state.user_data.is_mm === 1 && this.state.sourceList.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  {this.state.user_data.hidden_mode === 0 && (
                    <div style={{ textAlign: "center" }}>
                      Scanning for: {this.state.user_data.mp_source.name} | Pallet Value: $
                      {this.state.user_data.mp_source.pallet_value} | Remaining: $
                      {this.state.user_data.mp_source.pallet_value - this.state.totalMPScanned > 0
                        ? (this.state.user_data.mp_source.pallet_value - this.state.totalMPScanned).toFixed(2)
                        : 0}
                    </div>
                  )}

                  <div style={{ marginTop: "8px", display: "flex" }}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setState({ isMPSourceInput: !this.state.isMPSourceInput })}
                    >
                      <i className="fa fa-info-circle " />
                    </div>
                    <div style={{ padding: "4px 0 5px 5px", flexGrow: 1 }}>
                      <ProgressBar
                        now={progressBarPercentage}
                        variant={
                          progressBarPercentage < 50
                            ? "primary"
                            : progressBarPercentage >= 50 && progressBarPercentage < 100
                            ? "warning"
                            : "danger"
                        }
                        // label={`$${this.state.totalMPScanned} out of $${this.state.user_data.mp_source.pallet_value} scanned`}
                      />
                    </div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  {this.state.isMPSourceInput && this.state.sourceList.length > 0 && (
                    <Dropdown
                      // options={this.state.sourceList.map((sl) => {
                      //   return { value: sl.name, label: sl.name + " - $" + sl.pallet_value };
                      // })}
                      options={this.state.sourceList.map((sl) => {
                        return {
                          value: sl.name,
                          label:
                            sl.name + (sl.pallet_notes !== "" && sl.pallet_notes !== null ? " " + sl.pallet_notes : ""),
                        };
                      })}
                      onChange={(source) => this.updateActiveSource(source.value)}
                      value={this.state.user_data.mp_source.name}
                    />

                    // <div style={{ display: "flex", marginTop: "5px" }}>
                    //   <div style={{ padding: "8px 10px 0 0", minWidth: "200px" }}>
                    //     Current Source: <strong>{this.state.user_data.mp_source.name}</strong>
                    //   </div>
                    // </div>
                  )}
                </div>
              )}

              {/* {this.state.isMPSourceInput && (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ padding: "8px 10px 0 0", minWidth: "200px" }}>
                    Current Source: <strong>ABCD</strong>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <FormControl
                      value={this.state.sourceName}
                      onChange={(e) => this.setState({ sourceName: e.target.value })}
                      placeholder="Source"
                      type={"text"}
                      className="mp-source"
                    />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <FormControl
                      value={this.state.sourcePalletValue}
                      onChange={(e) => this.setState({ sourcePalletValue: e.target.value })}
                      placeholder="Pallet Value"
                      type={"number"}
                      className="mp-source"
                    />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <Button className="mp-source" onClick={this.saveNewMPSource}>
                      Add
                    </Button>
                  </div>
                </div>
              )} */}
            </div>

            {this.state.showAsinSelectModal && (
              <Row>
                <Col md={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Preview</th>
                        <th>Title</th>
                        <th className="tableColMobHide">ASIN</th>
                        <th className="tableColMobHide">Category</th>
                        <th className="tableColMobHide">Rank</th>
                        {/* <th className="tableColMobHide">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.multipleItems !== undefined &&
                        this.state.multipleItems.map((singleItem) => {
                          return (
                            <tr onClick={() => this.selectAsin(singleItem.asin)} style={{ cursor: "pointer" }}>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                <div>
                                  <Image src={singleItem.image_url} />
                                </div>
                                <div
                                  className="onlyOnMobile"
                                  style={{
                                    marginTop: "10px",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    textTransform: "uppercase",
                                    color: "purple",
                                  }}
                                >
                                  {singleItem.product_group}
                                </div>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <div className="smallFontOnMobile">{singleItem.title}</div>
                                <div className="onlyOnMobile">
                                  <div>
                                    <strong>ASIN:</strong> {singleItem.asin} | <strong>RANK:</strong>:{" "}
                                    {singleItem.sales_rank !== 100000000 ? (
                                      this.numberWithCommas(singleItem.sales_rank)
                                    ) : (
                                      <span style={{ color: "red" }}>N/A</span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td style={{ verticalAlign: "middle" }} className="tableColMobHide">
                                {singleItem.asin}
                              </td>
                              <td style={{ verticalAlign: "middle" }} className="tableColMobHide">
                                {singleItem.product_group}
                              </td>
                              <td style={{ verticalAlign: "middle" }} className="tableColMobHide">
                                {singleItem.sales_rank !== 100000000 ? (
                                  this.numberWithCommas(singleItem.sales_rank)
                                ) : (
                                  <span style={{ color: "red" }}>N/A</span>
                                )}
                              </td>
                              {/* <td style={{ verticalAlign: "middle" }} className="tableColMobHide">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    alert(singleItem.asin);
                                    this.selectAsin(singleItem.asin);
                                  }}
                                >
                                  Choose
                                </Button>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            {"formatted" in this.state.asinData && this.state.user_data.show_sales_rank === 1 && (
              <Row>
                <Col md={12}>
                  <div className="item-meta">
                    eScore:{" "}
                    <span style={{ fontWeight: "bold", color: "beige" }}>{this.state.asinData.formatted.escore}</span>{" "}
                    <span style={{ marginLeft: "20px" }}>Rank: </span>
                    <span style={{ fontWeight: "bold", color: "beige" }}>
                      {this.state.asinData.formatted.salesrank}
                    </span>
                  </div>
                </Col>
              </Row>
            )}

            {"status" in this.state.asinData && this.state.asinData.status === "success" && (
              <AsinTitle
                updatingWinner={this.state.updatingWinner}
                // isRestricted={this.state.isRestricted}
                printISBN={this.printISBNLabel}
                asinMeta={this.state.asinData.meta}
                title={this.state.asinData.meta.title}
                teamId={"team_id" in this.state.user_data ? this.state.user_data.team_id : -1}
                categories={this.state.asinData.meta.itemCategories}
                isValidBuy={this.state.isValidBuy}
                invalidAsin={this.state.invalidAsin}
                //ValidBuySource={this.state.ValidBuySource}
                moduleWinnerColor={this.state.moduleWinnerColor}
                moduleWinnerText={this.state.moduleWinnerText}
                moduleWinner={this.state.moduleWinner}
                subModuleWinner={this.state.subModuleWinner}
                winnerDisplay={
                  this.state.winnerMap !== null
                    ? this.state.winnerMap.display
                    : { name: "N/A", bgColor: "red", textColor: "white" }
                }
              />
            )}
            {this.state.invalidAsin && <div className="titleAndDecisionInvalid">No Results Found</div>}
            {this.state.user_data.hidden_mode === 1 &&
              this.state.user_data.show_sales_rank === 1 &&
              this.state.invalidAsin === false &&
              this.state.fetchingAsinData === false &&
              "formatted" in this.state.asinData && (
                <div className="show_sales_rank">Sales Rank: {this.state.asinData.formatted.salesrank}</div>
              )}
            {"infoboxes_enabled" in this.state.user_data &&
              (this.state.infoBoxesData.length > 0 ||
                ("prices" in this.state.asinData &&
                  "bs" in this.state.asinData.prices &&
                  this.state.asinData.prices.bs.length > 0)) &&
              this.state.invalidAsin === false &&
              this.state.user_data.infoboxes_enabled === 1 && (
                <InfoBoxes
                  logBSClick={this.logBSClick}
                  isRestricted={this.state.isRestricted}
                  asinMeta={this.state.asinData.meta}
                  bsPrices={"bs" in this.state.asinData.prices ? this.state.asinData.prices.bs : []}
                  handleBSDoubleClick={this.handleBSDoubleClick}
                  updateStaticWinner={this.updateStaticWinner}
                  user_data={this.state.user_data}
                  asin={this.state.currentASIN}
                  infoBoxesData={this.state.infoBoxesData}
                />
              )}
            {"keepa" in this.state.asinData && (
              <Row>
                <Col md={12}>
                  <KeepaData data={this.state.asinData.keepa} offers={this.state.asinData.offers} />
                </Col>
              </Row>
            )}
            {"status" in this.state.asinData &&
              this.state.user_data.hidden_mode !== 1 &&
              this.state.asinData.status === "success" && (
                <ItemInfo asinData={this.state.asinData} infoBoxesData={this.state.infoBoxesData} />
              )}
          </Container>
        </React.Fragment>
      ) : (
        <div></div>
      );
    return componentToReturn;
  }
}

export default Home;

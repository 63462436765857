import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class ItemInfo extends Component {
  state = {};

  addZeroes(amount) {
    if (amount === "N/A" || amount === undefined) return amount;
    else {
      // Cast as number
      let num = Number(amount);
      // If not a number, return 0
      if (isNaN(num)) {
        return 0;
      }
      // If there is no decimal, or the decimal is less than 2 digits, toFixed
      if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2) {
        num = num.toFixed(2);
      }
      // Return the number
      return num;
    }
  }

  render() {
    return (
      <div className="itemInfoSection">
        <Row className="itemInfoRow" style={{ marginTop: "5px" }}>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">Target Price (FBA)</div>
              <div className="itemInfoValue">
                {"price_target_fba" in this.props.asinData.winner.amazonLogData &&
                this.props.asinData.winner.amazonLogData.price_target_fba > 0
                  ? "$" + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_fba.toFixed(2))
                  : "$0"}
              </div>

              <div className="itemInfoHeading marginT15">Target Price (MF)</div>
              <div className="itemInfoValue">
                {"price_target_mf" in this.props.asinData.winner.amazonLogData &&
                this.props.asinData.winner.amazonLogData.price_target_mf > 0
                  ? "$" + this.addZeroes(this.props.asinData.winner.amazonLogData.price_target_mf.toFixed(2))
                  : "$0"}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">Trade-in Value</div>
              <div className="itemInfoValue">${this.props.asinData.formatted.tradeIn}</div>
              <div className="itemInfoHeading marginT15">Weight</div>
              <div className="itemInfoValue">{this.props.asinData.formatted.itemWeight}</div>
            </div>
          </Col>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">Rank</div>
              <div className="itemInfoValue">{this.props.asinData.formatted.salesrank}</div>
              <div className="itemInfoHeading marginT15">eScore</div>
              <div className="itemInfoValue">{this.props.asinData.formatted.escore}</div>
            </div>
          </Col>
        </Row>
        <Row className="itemInfoRow">
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">{this.props.asinData.meta.offer_count_used} Used Offers</div>
              <div className="itemInfoValue">
                {this.props.asinData.offers.used.map((usedOffer, _index) => (
                  <div key={"up" + _index}>${this.addZeroes(usedOffer)}</div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">{this.props.asinData.offers.fba.length} FBA Offers</div>
              <div className="itemInfoValue">
                {this.props.asinData.offers.fba.map((fbaOffer, _index) => (
                  <div key={"fo" + _index}>${this.addZeroes(fbaOffer)}</div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="itemInfo">
              <div className="itemInfoHeading">Used BB</div>
              <div className="itemInfoValue">
                {this.props.asinData.formatted.usedBB > 0
                  ? "$" + this.addZeroes(this.props.asinData.formatted.usedBB)
                  : "N/A"}
              </div>
              <div className="itemInfoHeading marginT15">New BB</div>
              <div className="itemInfoValue">
                {this.props.asinData.formatted.newBB > 0
                  ? "$" + this.addZeroes(this.props.asinData.formatted.newBB)
                  : "N/A"}
              </div>
              <div className="itemInfoHeading marginT15">Low New</div>
              <div className="itemInfoValue">
                {this.props.asinData.formatted.newPrice > 0
                  ? "$" + this.addZeroes(this.props.asinData.formatted.newPrice)
                  : "N/A"}
              </div>
              <div className="itemInfoHeading marginT15">Amazon Price</div>
              <div className="itemInfoValue">
                {this.props.asinData.formatted.amazonPrice > 0
                  ? "$" + this.addZeroes(this.props.asinData.formatted.amazonPrice)
                  : "N/A"}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ItemInfo;

import React, { Component } from "react";
import { Navbar, Nav, Form } from "react-bootstrap";
import { homePageLogo, logoWidth } from "../Config";
import auth from "../Auth";
import ModalListingProfile from "../components/ModalListingProfile";

class TopMenu extends Component {
  state = {
    homePageLogo: homePageLogo,
    showNewLPModal: false,
  };

  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  // createNewLP() {
  //   alert(`Hello`);
  // }

  render() {
    const domainName = window.location.hostname;

    return (
      <Navbar bg="dark" expand="lg" variant="dark">
        <ModalListingProfile
          showNewLPModal={this.state.showNewLPModal}
          handleClose={() => this.setState({ showNewLPModal: false })}
        />

        <Navbar.Brand href="#home">
          <div>
            <img
              style={{ width: `${logoWidth}px` }}
              alt="logo"
              className="logo-home"
              src={require(`../assets/images/${this.state.homePageLogo}`)}
            />
          </div>

          <div style={{ clear: "both" }}></div>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {domainName !== "app.emptyshelves.com" && domainName !== "app.bookteriors.com" && (
              <Nav.Item>
                {this.props.isPrinterServerConnected === true ? (
                  <Nav.Link href="#home">
                    <div style={{ marginRight: "10px" }}>
                      <Form.Check
                        onChange={this.props.handleAutoPrint}
                        style={{ color: "#fff" }}
                        type="switch"
                        id="custom-switch-auto-print"
                        label="Auto Print"
                      />
                    </div>
                  </Nav.Link>
                ) : (
                  <span className="dot-disconnected"></span>
                )}
              </Nav.Item>
            )}

            {this.props.isMM !== null && domainName !== "app.emptyshelves.com" && domainName !== "app.bookteriors.com" && (
              <Nav.Item>
                <Nav.Link href="#home">
                  <div style={{ marginRight: "10px" }}>
                    <Form.Check
                      onChange={this.props.handleMMToggle}
                      style={{ color: "#fff" }}
                      type="switch"
                      variant="success"
                      id="custom-switch-mm"
                      label="Marketplace"
                      defaultChecked={this.props.isMM === 1 ? true : false}
                    />
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}

            {domainName !== "app.emptyshelves.com" && domainName !== "app.bookteriors.com" && (
              <Nav.Item>
                <Nav.Link href="#home">
                  <div style={{ marginRight: "10px" }}>
                    <Form.Check
                      onChange={this.props.handleLiveToggle}
                      style={{ color: "#fff" }}
                      type="switch"
                      id="custom-switch-live-load"
                      label="Live"
                    />
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}

            {/* <NavDropdown title="Listing Profile" id="collasible-nav-dropdown">
              {this.props.listingProfiles.map((lp) => (
                <NavDropdown.Item>
                  <div></div>
                  <Row>
                    <Col md={9} onClick={() => this.updateLP(lp.name)}>
                      {lp.name}
                    </Col>
                    <Col md={3}>
                      <i onClick={() => this.EditLP(lp.id)} className="fa fa-pencil lp-actions" />
                      <i onClick={() => this.EditLP(lp.id)} className="fa fa-close lp-actions" />
                    </Col>
                  </Row>
                </NavDropdown.Item>
              ))}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => this.setState({ showNewLPModal: true })}>Create New</NavDropdown.Item>
            </NavDropdown> */}

            <Nav.Item>
              <Nav.Link href="#home">
                <div style={{ marginRight: "10px" }}>
                  <Form.Check
                    onChange={this.props.handleSoundToggle}
                    style={{ color: "#fff" }}
                    type="switch"
                    defaultChecked={this.props.shouldPlaySound}
                    id="custom-switch-sound-toggle"
                    label="Sound"
                  />
                </div>
              </Nav.Link>
            </Nav.Item>

            {domainName !== "app.bookteriors.com" && domainName !== "app.emptyshelves.com" && (
              <Nav.Item>
                <Nav.Link onClick={this.props.handleOpenShipmentMap}>Shipments</Nav.Link>
              </Nav.Item>
            )}

            {/* <NavDropdown title="Printer Driver" id="download-bct">
              <NavDropdown.Item href="bct.pkg">Mac OS</NavDropdown.Item>
              <NavDropdown.Item href="bct.exe">Windows</NavDropdown.Item>
              <NavDropdown.Item href="bct.run">Linux</NavDropdown.Item>
            </NavDropdown> */}

            {domainName !== "app.bookteriors.com" && domainName !== "app.emptyshelves.com" && (
              <Nav.Item>
                <Nav.Link onClick={this.props.handleOpenSettingsModal}>Settings</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopMenu;

import React, { Component } from "react";
import { Button } from "react-bootstrap";

class StepIntroVideo extends Component {
  constructor(props) {
    super(props);
    this.enterKeyFunction = this.enterKeyFunction.bind(this);
  }

  enterKeyFunction(event) {
    if (event.keyCode === 13) this.props.gotoNextScreen();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.enterKeyFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.enterKeyFunction, false);
  }

  render() {
    return (
      <div
        onKeyPress={(target) => {
          if (target.charCode === 13) this.props.gotoNextScreen();
        }}
      >
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>
            Welcome to <span style={{ color: "#289D44" }}>INVITE ONLY</span> Ultimate Bulk Scanning Tool
          </h2>
          <h5 style={{ fontWeight: 100 }}>{this.props.infoMessageSmall}</h5>
        </div>

        <div>
          <iframe
            title="onboarding videeo palletiq caleb"
            style={{ maxWidth: "100%" }}
            width="889"
            height="500"
            src="https://www.youtube-nocookie.com/embed/EaFx1ndZM1M"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div style={{ marginTop: "50px" }}>
          <Button
            style={{ marginTop: "20px" }}
            variant="success"
            onClick={() => {
              this.props.gotoNextScreen();
            }}
          >
            Proceed to Signup
          </Button>
        </div>
      </div>
    );
  }
}

export default StepIntroVideo;

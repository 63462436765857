import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";

class KeepaData extends Component {
  state = {};

  render() {
    let priceMin = 0,
      pricePer20 = 0,
      priceMed = 0,
      pricePer80 = 0,
      priceMax = 0;
    const lowestUsed = this.props.offers.used[0];

    try {
      if ("scoutiqdata_bumppricemin_12mo" in this.props.data) {
        priceMin = this.props.data.scoutiqdata_bumppricemin_12mo;
        pricePer20 = this.props.data.scoutiqdata_bumpprice20th_12mo;
        priceMed = this.props.data.scoutiqdata_bumpprice50th_12mo;
        pricePer80 = this.props.data.scoutiqdata_bumpprice80th_12mo;
        priceMax = this.props.data.scoutiqdata_bumppricemax_12mo;
      } else if ("yearly_stats" in this.props.data) {
        priceMin = this.props.data.yearly_stats.min;
        pricePer20 = this.props.data.yearly_stats.percentile_20;
        priceMed = this.props.data.yearly_stats.median;
        pricePer80 = this.props.data.yearly_stats.percentile_80;
        priceMax = this.props.data.yearly_stats.max;
      } else {
        console.log(`No keepa data available`);
      }
    } catch (error) {
      console.log(error);
    }

    return (
      <Container>
        {("yearly_stats" in this.props.data && "min" in this.props.data.yearly_stats) ||
        "scoutiqdata_bumppricemin_12mo" in this.props.data ? (
          <div
            style={{
              width: "700px",
              maxWidth: "100%",
              margin: "0 auto",
              marginTop: "25px",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            {/* <Col md={1}></Col> */}
            <div
              style={{
                height: "23px",
                background: "#00B254",
                textAlign: "left",
                paddingLeft: "10px",
                borderTopLeftRadius: "3px",
                borderBottomLeftRadius: "3px",
                flex: "1",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#fff", fontSize: "13px" }}>{"$" + Math.floor(priceMin)}</span>
              {lowestUsed <= priceMin && (
                <div className="price-range-indicator" style={{ left: "-10%" }}>
                  <div className="current-price-indicator">${lowestUsed}</div>
                </div>
              )}
              {lowestUsed > priceMin && lowestUsed <= pricePer20 && (
                <div
                  className="price-range-indicator"
                  style={{
                    left: `${(((lowestUsed - priceMin) / (pricePer20 - priceMin)) * 100).toFixed(0)}%`,
                  }}
                >
                  <div className="current-price-indicator">${lowestUsed}</div>
                </div>
              )}
            </div>

            <div
              style={{
                height: "23px",
                background: "#1ABC9C",
                textAlign: "left",
                paddingLeft: "10px",
                flex: "1",
              }}
            >
              {pricePer20 !== priceMin && (
                <span style={{ fontWeight: "bold", color: "#fff", fontSize: "13px" }}>
                  {"$" + Math.floor(pricePer20)}
                </span>
              )}

              {lowestUsed > pricePer20 && lowestUsed <= priceMed && (
                <div
                  className="price-range-indicator"
                  style={{
                    left: `${(((lowestUsed - pricePer20) / (priceMed - pricePer20)) * 100).toFixed(0)}%`,
                  }}
                >
                  <div className="current-price-indicator">${lowestUsed}</div>
                </div>
              )}
            </div>

            <div
              style={{
                height: "23px",
                background: "#5E99CC",
                textAlign: "center",
                paddingLeft: "10px",
                flex: "1",
              }}
            >
              {priceMed !== pricePer20 && (
                <span style={{ fontWeight: "bold", color: "#fff", fontSize: "13px" }}>
                  {"$" + Math.floor(priceMed)}
                </span>
              )}
            </div>

            <div
              style={{
                height: "23px",
                background: "#F7610A",
                textAlign: "right",
                paddingLeft: "10px",
                flex: "1",
              }}
            >
              {pricePer80 !== priceMed && (
                <span style={{ fontWeight: "bold", color: "#fff", fontSize: "13px" }}>
                  {"$" + Math.floor(pricePer80)}
                </span>
              )}

              {lowestUsed > priceMed && lowestUsed <= pricePer80 && (
                <div
                  className="price-range-indicator"
                  style={{
                    left: `${(((lowestUsed - priceMed) / (pricePer80 - priceMed)) * 100).toFixed(0)}%`,
                  }}
                >
                  <div className="current-price-indicator">${lowestUsed}</div>
                </div>
              )}
            </div>

            <div
              md={2}
              style={{
                height: "23px",
                background: "#F20050",
                textAlign: "right",
                paddingRight: "10px",
                borderTopRightRadius: "3px",
                borderBottomRightRadius: "3px",
                flex: "1",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#fff", fontSize: "13px" }}>{"$" + Math.floor(priceMax)}</span>
              {lowestUsed > pricePer80 && lowestUsed <= priceMax && (
                <div
                  className="price-range-indicator"
                  style={{
                    left: `${(((lowestUsed - pricePer80) / (priceMax - pricePer80)) * 100).toFixed(0)}%`,
                  }}
                >
                  <div className="current-price-indicator">${lowestUsed}</div>
                </div>
              )}

              {lowestUsed > priceMax && (
                <div
                  className="price-range-indicator"
                  style={{
                    left: "110%",
                  }}
                >
                  <div className="current-price-indicator">${lowestUsed}</div>
                </div>
              )}
            </div>
            {/* <Col md={1}></Col> */}
          </div>
        ) : (
          <Row>
            <Col md={12} style={{ textAlign: "center", fontWeight: "bold" }}>
              Not available on Keepa
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default KeepaData;

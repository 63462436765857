import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";

class StepMinProfitInput extends Component {
  state = {
    errorMessage: "",
    inputsDisabled: false,
    lastSavedVal1: 0,
  };
  gotoNextStep() {
    if (this.props.inputVal1 === "" || this.props.inputVal1 === null) {
      this.setState({ errorMessage: "Input Required" });
    } else {
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>{this.props.heading}</h2>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p style={{ fontSize: "20px" }}>{this.props.message}</p>
          {this.props.smallMessage !== "" && (
            <div style={{ margin: "5px 0 15px 0" }}>
              <i>{this.props.smallMessage}</i>
            </div>
          )}

          <div>
            <table style={{ margin: "0 auto", borderWidth: "1px", borderColor: "#aaaaaa", borderStyle: "solid" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", padding: "20px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>eScore Range</div>
                  </td>
                  <td>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{this.props.escoreRange}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center", padding: "20px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{this.props.inputLabel1}</div>
                  </td>
                  <td style={{ padding: "20px" }}>
                    <Form.Control
                      onChange={(e) => {
                        this.props.exportVal1(e.target.value);
                      }}
                      onKeyPress={(target) => {
                        if (target.charCode === 13) this.gotoNextStep();
                      }}
                      ref={(input) => {
                        this.nameInput = input;
                      }}
                      value={this.props.inputVal1}
                      disabled={this.state.inputsDisabled}
                      style={{ width: "450px", maxWidth: "100%" }}
                      autoComplete="off"
                      type={this.props.inputType1 || "number"}
                      className="onboardingInputs"
                      placeholder={this.props.placeholderString1 || "Please enter a number. For example: $1.50"}
                      aria-describedby="basic-addon1"
                    />
                  </td>
                </tr>
                {this.props.isCheckBox !== false && (
                  <tr>
                    <td style={{ textAlign: "center", padding: "20px" }}>
                      <div style={{ fontSize: "16px", fontWeight: "bold" }}>Always Reject</div>
                    </td>
                    <td style={{ padding: "20px" }}>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          this.props.exportBinary(false);
                          if (e.target.checked) {
                            this.props.exportVal1(-1);

                            this.setState({
                              lastSavedVal1: this.props.inputVal1,
                              inputsDisabled: true,
                            });
                          } else {
                            this.props.exportVal1(this.state.lastSavedVal1);

                            this.setState({ inputsDisabled: false });
                          }
                        }}
                        checked={this.props.binaryVal}
                        // style={{ width: "450px", maxWidth: "100%" }}
                        autoComplete="off"
                        aria-describedby="basic-addon1"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.props.firstScreen !== true && (
              <Button
                style={{ margin: "20px 20px 0 0" }}
                variant="primary"
                onClick={() => {
                  this.setState({ errorMessage: "" });
                  this.props.gotoPreviousScreen();
                }}
              >
                Previous
              </Button>
            )}

            <Button style={{ marginTop: "20px" }} variant="primary" onClick={() => this.gotoNextStep()}>
              Next
            </Button>
          </div>
          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StepMinProfitInput;

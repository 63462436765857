import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";
import UIfx from "uifx";
import { apiBase, winnersMap } from "../Config";
import downloadMac from "../assets/images/download_mac.png";
import downloadWin from "../assets/images/download_win.png";
import downloadChrome from "../assets/images/download_chrome.png";

class ModalSettings extends Component {
  state = {
    isCeiling1Enabled: false,
    soundApiURL: apiBase.replace("/v1", ""),
    domainName: window.location.hostname,
    enabledMods: [],
    isSIQIntegration: false,
    fetchingEnabledMods: false,
    isAmzRestricted: false,
  };
  constructor() {
    super();
    this.handleC1Change = this.handleC1Change.bind(this);
    this.playSound = this.playSound.bind(this);
    this.onFormSubmitSaveSettings = this.onFormSubmitSaveSettings.bind(this);
    this.toggleSIQIntegration = this.toggleSIQIntegration.bind(this);
  }

  /** Will execute when option changes from dropdown.
   *  Plays audio from the URL
   */
  playSound(event) {
    console.log(event.target.value);
    for (let i = 0; i < this.props.audioList.length; i++) {
      if (this.props.audioList[i].name === event.target.value) {
        console.log(this.props.audioList[i].file_path);
        const soundToPlay = new UIfx(this.state.soundApiURL + this.props.audioList[i].file_path, {
          volume: 1.0, // number between 0.0 ~ 1.0
          throttleMs: 100,
        });
        soundToPlay.play();
        break;
      }
    }
  }

  handleC1Change(evt) {
    this.setState({ isCeiling1Enabled: evt.target.checked });
  }

  onFormSubmitSaveSettings(event) {
    event.preventDefault();

    const audioMP = "audioMP" in event.target ? event.target.audioMP.value : "";
    const audioES = "audioES" in event.target ? event.target.audioES.value : "";
    const audioFBA = "audioFBA" in event.target ? event.target.audioFBA.value : "";
    const audioMF = "audioMF" in event.target ? event.target.audioMF.value : "";
    const audioHitList = "audioHitList" in event.target ? event.target.audioHitList.value : "";
    const audioWS = "audioWS" in event.target ? event.target.audioWS.value : "";
    const audioRMS = "audioRMS" in event.target ? event.target.audioRMS.value : "";
    const audioSBYB = "audioSBYB" in event.target ? event.target.audioSBYB.value : "";
    const audioBS = "audioBS" in event.target ? event.target.audioBS.value : "";
    const audioSB = "audioSB" in event.target ? event.target.audioSB.value : "";
    const audioBR = "audioBR" in event.target ? event.target.audioBR.value : "";
    const audioUBB = "audioUBB" in event.target ? event.target.audioUBB.value : "";
    const audioReject = "audioReject" in event.target ? event.target.audioReject.value : "";
    const audioNoResult = "audioNoResult" in event.target ? event.target.audioNoResult.value : "";
    const audioZIFFIT = "audioZIFFIT" in event.target ? event.target.audioZIFFIT.value : "";
    const audioBRB = "audioBRB" in event.target ? event.target.audioBRB.value : "";
    const audioAmzRejected = "audioAmzRejected" in event.target ? event.target.audioAmzRejected.value : "";
    const logSource = "logSource" in event.target ? event.target.logSource.value : "";
    const scoutiq_user_id = "scoutiq_user_id" in event.target ? event.target.scoutiq_user_id.value : "";
    const isCheckRestricted = "isCheckRestricted" in event.target ? event.target.isCheckRestricted.value : "";
    const onRestrictedSkipAmazon =
      "onRestrictedSkipAmazon" in event.target ? event.target.onRestrictedSkipAmazon.value : "";

    const userSettingsJson = {
      audioMP,
      audioES,
      audioFBA,
      audioMF,
      audioHitList,
      audioWS,
      audioRMS,
      audioSBYB,
      audioBS,
      audioSB,
      audioBR,
      audioUBB,
      audioZIFFIT,
      audioBRB,
      audioReject,
      audioNoResult,
      audioAmzRejected,
      logSource,
      scoutiq_user_id,
      is_siq_integration: this.state.isSIQIntegration,
      isCheckRestricted,
      onRestrictedSkipAmazon,
    };

    console.log(userSettingsJson);

    this.props.submitSaveSettings(userSettingsJson);
  }

  toggleSIQIntegration(event) {
    this.setState({ isSIQIntegration: event.target.checked });
  }

  fetchModules = async () => {
    // Get initial settings
    const isAmzRestricted =
      this.props.userSettings !== null && "isCheckRestricted" in this.props.userSettings
        ? this.props.userSettings.isCheckRestricted
        : "no";
    this.setState({ isAmzRestricted });

    // Fetch modules here
    this.setState({ fetchingEnabledMods: true });
    try {
      const response = await fetch(apiBase + "user/enabled_mods", {
        method: "POST",
        body: new URLSearchParams({
          user_id: this.props.user_data.user_id,
          app_token: this.props.user_data.token,
        }),
      });
      if (response.status === 200) {
        const enabledModsResponse = await response.json();

        var enabledMods = [];
        if (this.props.isMM)
          enabledMods.push({
            display: "MARKETPLACE",
            soundFieldName: "audioMP",
          });
        enabledModsResponse.data.forEach((em) => {
          const mapped = winnersMap.find(
            (winner) => winner.conditions.subModule === em || winner.conditions.module === em
          );
          if (undefined === mapped) {
            console.log(`HIS IS: ${em}`);
          }
          var displayName =
            mapped.display.name.indexOf("[SUB_MODULE]") === -1 ? mapped.display.name : mapped.conditions.module;
          if (mapped !== undefined && enabledMods.find((emCheck) => emCheck.display === displayName) === undefined)
            enabledMods.push({
              display: displayName,
              soundFieldName: mapped.display.sound,
            });
        });
        enabledMods.push({
          display: "REJECT",
          soundFieldName: "audioReject",
        });
        enabledMods.push({
          display: "NO RESULT",
          soundFieldName: "audioNoResult",
        });
        this.setState({ enabledMods });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ fetchingEnabledMods: false });
    }
  };

  render() {
    return (
      <Modal
        className="modal-user-settings"
        show={this.props.showSettingsModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
        onEnter={this.fetchModules}
      >
        <Form onSubmit={this.onFormSubmitSaveSettings}>
          <Modal.Header closeButton>
            <h3>User Settings | {this.props.user_data.first_name}</h3>
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500", marginTop: "20px" }}>
              <h5>Sounds</h5>
              <div>
                {this.state.fetchingEnabledMods === true ? (
                  <Spinner
                    style={{ width: "20px", height: "20px", margin: "10px 0 10px 0" }}
                    animation="border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  this.state.enabledMods.map((em) => {
                    return (
                      <Row>
                        <Col style={{ paddingTop: "7px" }} md={4}>
                          {["REJECT", "NO RESULT"].indexOf(em.display) > -1 ? (
                            <span style={{ color: "red" }}>{em.display}</span>
                          ) : (
                            em.display
                          )}
                        </Col>
                        <Col md={8}>
                          <Form.Group controlId="formCeiling1Options">
                            <Form.Control
                              style={{ fontSize: "13px" }}
                              as="select"
                              name={em.soundFieldName}
                              onChange={this.playSound}
                              defaultValue={
                                this.props.userSettings !== null && em.soundFieldName in this.props.userSettings
                                  ? this.props.userSettings[em.soundFieldName]
                                  : ""
                              }
                            >
                              <option key={"es_audio_blank"} value=""></option>
                              {this.props.audioList.map((audioSound, _index) => {
                                return (
                                  <option key={"es_audio_" + audioSound.name} value={audioSound.name}>
                                    {audioSound.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    );
                  })
                )}
              </div>
            </div>

            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "30px",
                borderTop: "1px solid #F4F4F4",
                paddingTop: "20px",
              }}
            >
              <h5>Misc</h5>

              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <Row>
                    <Col style={{ paddingTop: "8px" }} md={4}>
                      Check Amazon Restrictions?
                    </Col>
                    <Col md={8}>
                      <Form.Control
                        as="select"
                        name="isCheckRestricted"
                        onChange={(e) => {
                          this.setState({ isAmzRestricted: e.target.value });
                        }}
                        defaultValue={
                          this.props.userSettings !== null && "isCheckRestricted" in this.props.userSettings
                            ? this.props.userSettings.isCheckRestricted
                            : "no"
                        }
                      >
                        <option value="yes">YES</option>
                        <option value="no">NO</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  {this.state.isAmzRestricted === "yes" && (
                    <Row style={{ marginTop: "10px" }}>
                      <Col style={{ paddingTop: "8px" }} md={4}>
                        Sound on Amazon Restricted
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="audioAmzRejected"
                          onChange={this.playSound}
                          defaultValue={
                            this.props.userSettings !== null && "audioAmzRejected" in this.props.userSettings
                              ? this.props.userSettings.audioAmzRejected
                              : ""
                          }
                        >
                          <option key={"es_audio_blank"} value=""></option>
                          {this.props.audioList.map((audioSound, _index) => {
                            return (
                              <option key={"es_audio_" + audioSound.name} value={audioSound.name}>
                                {audioSound.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Row>
                  )}

                  {this.state.isAmzRestricted === "yes" && (
                    <Row style={{ marginTop: "10px" }}>
                      <Col style={{ paddingTop: "8px" }} md={4}>
                        Skip Amazon for Restricted Items?
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          as="select"
                          name="onRestrictedSkipAmazon"
                          defaultValue={
                            this.props.userSettings !== null && "onRestrictedSkipAmazon" in this.props.userSettings
                              ? this.props.userSettings.onRestrictedSkipAmazon
                              : ""
                          }
                        >
                          <option value="yes">YES</option>
                          <option value="no">NO</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  )}

                  <Row style={{ marginTop: "10px" }}>
                    <Col md={12}>
                      <Row>
                        <Col style={{ paddingTop: "8px" }} md={4}>
                          Log Source
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            name="logSource"
                            defaultValue={
                              this.props.userSettings !== null && "logSource" in this.props.userSettings
                                ? this.props.userSettings.logSource
                                : ""
                            }
                            placeholder="Enter Source"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <Col md={4} style={{ paddingTop: "10px" }}>
                      <Form.Check
                        type="switch"
                        defaultChecked={
                          this.props.userSettings !== null && "is_siq_integration" in this.props.userSettings
                            ? this.props.userSettings.is_siq_integration
                            : false
                        }
                        name="isActive"
                        id="is-active-siq"
                        onChange={(e) => this.toggleSIQIntegration(e)}
                        label="ScoutIQ User ID for OCR"
                      />
                    </Col>

                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="scoutiq_user_id"
                        defaultValue={
                          this.props.userSettings !== null && "scoutiq_user_id" in this.props.userSettings
                            ? this.props.userSettings.scoutiq_user_id
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "30px",
                borderTop: "1px solid #F4F4F4",
                paddingTop: "20px",
              }}
            >
              <h5>Downloads</h5>

              <div style={{ marginTop: "20px" }}>
                <a href="https://files.palletiq.com/printer/macbuild2.dmg" target="_blank" rel="noopener noreferrer">
                  <img alt="macOS" style={{ width: "40px" }} src={downloadMac} /> PRINTER - MACOS
                </a>
                <a href="https://files.palletiq.com/printer/winbuild2.exe" target="_blank" rel="noopener noreferrer">
                  <img alt="winOS" style={{ width: "40px", marginLeft: "35px" }} src={downloadWin} /> PRINTER - WINDOWS
                </a>
                <a
                  href="https://files.palletiq.com/palletiq-chrome-extension-v1.1.zip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="winOS" style={{ width: "40px", marginLeft: "35px" }} src={downloadChrome} /> CHROME
                  EXTENSION
                </a>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {this.props.uploadingSettings === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalSettings;

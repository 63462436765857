import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";

class StepSingleInput extends Component {
  state = {
    errorMessage: "",
  };
  gotoNextStep() {
    if (this.props.inputVal === "" || this.props.inputVal === null) {
      this.setState({ errorMessage: "Input Required" });
    } else {
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>{this.props.heading}</h2>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p style={{ fontSize: "18px" }}>{this.props.message}</p>
          {this.props.smallMessage !== "" && (
            <div style={{ margin: "5px 0 15px 0" }}>
              <i>{this.props.smallMessage}</i>
            </div>
          )}
          <Form.Control
            onChange={(e) => {
              this.props.exportVal(e.target.value);
            }}
            onKeyPress={(target) => {
              if (target.charCode === 13) this.gotoNextStep();
            }}
            ref={(input) => {
              this.nameInput = input;
            }}
            value={this.props.inputVal}
            style={{ width: "450px", maxWidth: "100%" }}
            autoComplete="off"
            type={this.props.inputType || "number"}
            className="onboardingInputs"
            placeholder={this.props.placeholderString || "Please enter a number. For example: $1.50"}
            aria-describedby="basic-addon1"
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.props.firstScreen !== true && (
              <Button
                style={{ margin: "20px 20px 0 0" }}
                variant="primary"
                onClick={() => {
                  this.setState({ errorMessage: "" });
                  this.props.gotoPreviousScreen();
                }}
              >
                Previous
              </Button>
            )}

            <Button style={{ marginTop: "20px" }} variant="primary" onClick={() => this.gotoNextStep()}>
              Next
            </Button>
          </div>
          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StepSingleInput;

import React, { Component } from "react";
import { Button, Form, Spinner } from "react-bootstrap";

class StepAmazonCredentials extends Component {
  state = {
    errorMessage: "",
  };
  gotoNextStep() {
    if (
      this.props.inputVal1 === "" ||
      this.props.inputVal1 === null ||
      this.props.inputVal2 === "" ||
      this.props.inputVal2 === null
    ) {
      this.setState({ errorMessage: "Input Required" });
    } else {
      this.setState({ errorMessage: "" });
      this.props.gotoNextScreen();
    }
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <h2 style={{ fontWeight: "100", fontSize: "28px", lineHeight: "1.5em" }}>{this.props.heading}</h2>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p style={{ fontSize: "20px" }}>{this.props.message}</p>
          {this.props.smallMessage !== "" && (
            <div style={{ margin: "5px 0 15px 0" }}>
              <i>{this.props.smallMessage}</i>
            </div>
          )}

          <div>
            <table style={{ margin: "0 auto", borderWidth: "1px", borderColor: "#aaaaaa", borderStyle: "solid" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", padding: "20px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{this.props.inputLabel1}</div>
                  </td>
                  <td>
                    <Form.Control
                      onChange={(e) => {
                        this.props.exportVal1(e.target.value);
                      }}
                      onKeyPress={(target) => {
                        if (target.charCode === 13) this.gotoNextStep();
                      }}
                      ref={(input) => {
                        this.nameInput = input;
                      }}
                      value={this.props.inputVal1}
                      style={{ width: "450px", maxWidth: "100%" }}
                      autoComplete="off"
                      type={this.props.inputType1 || "number"}
                      className="onboardingInputs"
                      placeholder={this.props.placeholderString1 || "Please enter a number. For example: $1.50"}
                      aria-describedby="basic-addon1"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center", padding: "20px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{this.props.inputLabel2}</div>
                  </td>
                  <td style={{ padding: "20px" }}>
                    <Form.Control
                      onChange={(e) => {
                        this.props.exportVal2(e.target.value);
                      }}
                      onKeyPress={(target) => {
                        if (target.charCode === 13) this.gotoNextStep();
                      }}
                      value={this.props.inputVal2}
                      style={{ width: "450px", maxWidth: "100%" }}
                      autoComplete="off"
                      type={this.props.inputType2 || "number"}
                      className="onboardingInputs"
                      placeholder={this.props.placeholderString2 || "Please enter a number. For example: $1.50"}
                      aria-describedby="basic-addon1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.props.firstScreen !== true && (
              <Button
                style={{ margin: "20px 20px 0 0" }}
                variant="primary"
                onClick={() => {
                  this.setState({ errorMessage: "" });
                  this.props.gotoPreviousScreen();
                }}
              >
                Previous
              </Button>
            )}

            <Button style={{ marginTop: "20px" }} variant="primary" onClick={() => this.gotoNextStep()}>
              {this.props.validatingMWS === true ? (
                <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Next"
              )}
            </Button>
          </div>
          {this.state.errorMessage !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          {this.props.errorMWS !== "" && (
            <div
              style={{
                color: "#702B2E",
                padding: "5px",
                background: "#F3D8DA",
                width: "275px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "10px",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              {this.props.errorMWS}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StepAmazonCredentials;

/**
 * Helper Class; contains functions mostly associated with API calls.
 */

import { apiBase } from "../Config";
import API from "./Api";

//import { updateMaxProfit } from "../../../backend/includes/helperUser";

function addZeroes(amount) {
  if (amount === "N/A" || amount === undefined) return amount;
  else {
    // Cast as number
    let num = Number(amount);
    // If not a number, return 0
    if (isNaN(num)) {
      return 0;
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2) {
      num = num.toFixed(2);
    }
    // Return the number
    return num;
  }
}

// Extract infoboxes prices from the API output
export const getInfoBoxesPrices = (asinData, isMM) => {
  let infoBoxes = [];

  // mm_index
  // const mmIndex = asinData.prices.vendors.findIndex((vIndex) => vIndex.vendor === "MP");

  // if ("fba_trigger_selected" in asinData.winner.amazonLogData && isMM === 1 && mmIndex === -1) {
  //   infoBoxes.push({
  //     name: "MP",
  //     price: 0,
  //     isAccepted: false,
  //   });
  // }
  if (
    "fba_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON")
  ) {
    // Amazon FBA
    const price =
      "estimated_profit_fba" in asinData.winner.amazonLogData ? asinData.winner.amazonLogData.estimated_profit_fba : 0;
    const isAccepted =
      "fba_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.fba_decision === 1
        ? true
        : false;
    infoBoxes.push({
      name: "FBA",
      price: price,
      isAccepted: isAccepted,
    });
  }
  // if ("estimated_profit_fba" in asinData.winner.amazonLogData) {
  // }
  // else {
  //   infoBoxes.push({
  //     name: "FBA",
  //     price: 0,
  //     isAccepted: false,
  //   });
  // }
  // Amazon MF

  if (
    "mf_trigger_selected" in asinData.winner.amazonLogData &&
    (isMM !== 1 || asinData.winner.winnerModule === "AMAZON")
  ) {
    const price =
      "estimated_profit_mf" in asinData.winner.amazonLogData ? asinData.winner.amazonLogData.estimated_profit_mf : 0;
    const isAccepted =
      "mf_decision" in asinData.winner.amazonLogData && asinData.winner.amazonLogData.mf_decision === 1 ? true : false;

    infoBoxes.push({
      name: "MF",
      price: price,
      isAccepted: isAccepted,
    });
  }
  // WholeSellers

  const sbIndex = asinData.prices.vendors.findIndex((ven) => ven.vendor === "SB");
  const rmsIndex = asinData.prices.vendors.findIndex((ven) => ven.vendor === "RMS");

  asinData.prices.vendors.forEach((v) => {
    if (v.vendor === "RMS" || v.vendor === "SB") return;
    else if (
      v.vendor === "MP" &&
      (asinData.winner.winnerModule === "" || asinData.winner.amazonLogData.is_match_mp === 0)
    )
      infoBoxes.push({
        name: "MP",
        price: 0,
        isAccepted: false,
      });
    else
      infoBoxes.push({
        name: v.vendor === "BOOKSCOUTER" ? "BS" : v.vendor,
        price: v.price,
        isAccepted: v.price > 0 ? true : false,
      });
  });

  // This will bring RMS to the right of SBYB
  const sbybIndex = infoBoxes.findIndex((ib) => ib.name === "SBYB");
  if (sbybIndex >= 0) {
    infoBoxes.push(infoBoxes.splice(sbybIndex, 1)[0]);
  }
  if (sbIndex >= 0 || rmsIndex >= 0) {
    let sbPrice = 0,
      rmsPrice = 0;

    sbPrice = sbIndex >= 0 ? asinData.prices.vendors[sbIndex].price : 0;
    rmsPrice = rmsIndex >= 0 ? asinData.prices.vendors[rmsIndex].price : 0;
    const higher_price = Math.max(sbPrice, rmsPrice);

    infoBoxes.push({
      name: "RMS",
      price: higher_price,
      isAccepted: higher_price > 0 ? true : false,
    });
  }

  // This will bring UBB to the end
  const ubbIndex = infoBoxes.findIndex((ib) => ib.name === "UBB");
  if (ubbIndex >= 0) {
    infoBoxes.push(infoBoxes.splice(ubbIndex, 1)[0]);
  }

  return infoBoxes;
};

export const addNewMPSource = async (sourceName, sourcePalletValue, userId, token) => {
  try {
    const response = await fetch(apiBase + "sources/", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        name: sourceName,
        pallet_value: sourcePalletValue,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const overrideLP = async (userId, token, scanId, commandKey, commandVal) => {
  try {
    const response = await fetch(apiBase + "isbn/overridelp/" + scanId, {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        override_key: commandKey,
        override_val: commandVal,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const overrideShipment = async (userId, token, scanId, shipmentName) => {
  try {
    const response = await fetch(apiBase + "isbn/overrideshipment", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        scan_id: scanId,
        shipment_name: shipmentName,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const overrideSource = async (userId, token, newSource) => {
  try {
    const response = await fetch(apiBase + "user/update-source", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
        new_source: newSource,
      }),
    });
    const apiResponse = await response.json();
    if (response.status === 200) {
      return { status: true, data: apiResponse };
    } else {
      return { status: false, error: apiResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const toggleMP = async (userId, token) => {
  try {
    const response = await fetch(apiBase + "marketplace/toggle", {
      method: "POST",
      body: new URLSearchParams({
        user_id: userId,
        app_token: token,
      }),
    });
    const apiResponse = await response.json();
    return apiResponse;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const formatAsinData = (asinData) => {
  asinData.formatted = {};
  if ("status" in asinData && asinData.status === "success") {
    // Format Salesrank to "K" (Thousands) & "M" (Millions)
    asinData.formatted.salesrank =
      asinData.meta.sales_rank > 0 && asinData.meta.sales_rank <= 999
        ? asinData.meta.sales_rank
        : asinData.meta.sales_rank > 999 && asinData.meta.sales_rank < 999999
        ? Math.round(asinData.meta.sales_rank / 1000) + "k"
        : asinData.meta.sales_rank > 999999
        ? (asinData.meta.sales_rank / 1000000).toFixed(1) + "M"
        : "N/A";

    // Format Title
    asinData.formatted.title = "title" in asinData.meta && asinData.meta.title !== "" ? asinData.meta.title : "N/A";

    // Format Trade-In Price
    asinData.formatted.tradeIn =
      asinData.prices.trade_in && parseFloat(asinData.prices.trade_in) > 0 ? asinData.prices.trade_in : 0;

    // Format Amazon Price
    asinData.formatted.amazonPrice =
      "amazon_price" in asinData.prices && parseFloat(asinData.prices.amazon_price) > 0
        ? "$" + parseFloat(asinData.prices.amazon_price).toFixed(2)
        : "N/A";

    // Format Weight
    asinData.formatted.itemWeight =
      "weight" in asinData.meta && asinData.meta.weight > 0
        ? addZeroes((asinData.meta.weight / 100).toFixed(2)) + " lbs"
        : "N/A";

    // Format BuyBox Prices
    asinData.formatted.usedBB = asinData.prices.buybox_used > 0 ? asinData.prices.buybox_used : 0;
    asinData.formatted.newBB = asinData.prices.buybox_new > 0 ? asinData.prices.buybox_new : 0;
    asinData.formatted.newPrice =
      "lowest_new" in asinData.prices && parseFloat(asinData.prices.lowest_new) > 0 ? asinData.prices.lowest_new : 0;
    asinData.formatted.amazonPrice =
      "amazon_price" in asinData.prices && parseFloat(asinData.prices.amazon_price) > 0
        ? asinData.prices.amazon_price
        : 0;

    // Check eScore
    asinData.formatted.escore =
      "e_score" in asinData.meta && asinData.meta.e_score >= 0 ? asinData.meta.e_score : "N/A";

    return asinData;
  }
};

export const updateUserSettings = async (user_id, token, userSettings) => {
  try {
    const response = await fetch(apiBase + "user/settings", {
      method: "PUT",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        user_settings: JSON.stringify(userSettings),
      }),
    });
    const saveSettingsResponse = await response.json();
    if (response.status === 200) {
      return { status: 1, data: saveSettingsResponse };
    } else {
      return { status: 0, error: saveSettingsResponse };
    }
  } catch (error) {
    return { status: 0, error: error };
  }
};

// export const fetchSourceList = async (user_id, token) => {
//   try {
//     const response = await fetch(apiBase + "marketplace/list_sources", {
//       method: "POST",
//       body: new URLSearchParams({
//         user_id: user_id,
//         app_token: token,
//       }),
//     });
//     return response.status === 200 ? await response.json() : false;
//   } catch (error) {
//     return { status: 0, error: error };
//   }
// };

export const fetchListingProfiles = async (user_id, token) => {
  try {
    const response = await fetch(apiBase + "listing/get_profiles", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const addListingProfile = async (user_id, token, profile_name, profile_json) => {
  try {
    const response = await fetch(apiBase + "listing/profile", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        profile_name: profile_name,
        profile_json: JSON.stringify(profile_json),
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const updateCurrentShipment = async (shipmentName, displayName, user_id, token) => {
  try {
    const response = await fetch(apiBase + "shipments/update_current", {
      method: "POST",
      body: new URLSearchParams({
        shipment_name: shipmentName,
        display_name: displayName,
        user_id: user_id,
        app_token: token,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const logBookScouter = async (scanId, asin, bsPrice, user_id, token) => {
  try {
    const response = await fetch(apiBase + "isbn/log_bsclick", {
      method: "POST",
      body: new URLSearchParams({
        scan_id: scanId,
        asin: asin,
        bs_vendor: bsPrice.vendor,
        bs_price: bsPrice.price,
        user_id: user_id,
        app_token: token,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const ffCombinedResponse = async (user_id, token) => {
  let promisesFF = [],
    finalResponse = [];
  promisesFF.push(
    fetch(apiBase + "shipments/list_all", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
      }),
    })
  );
  promisesFF.push(fetch(apiBase + "audio/list"));
  promisesFF.push(
    fetch(apiBase + "listing/get_profiles", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
      }),
    })
  );
  promisesFF.push(
    fetch(apiBase + "marketplace/list_sources", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
      }),
    })
  );

  const ffResponses = await Promise.all(promisesFF.map((p) => p.catch((error) => null)));
  console.log(`G2`);
  for (let i = 0; i < ffResponses.length; i++) {
    const response = ffResponses[i];
    let responseFormatted = response.status === 200 ? await response.json() : false;
    if (i === 0) responseFormatted.api = "shipments_list_all";
    else if (i === 1) responseFormatted.api = "audio_list";
    else if (i === 2) responseFormatted.api = "listing_profiles";
    else responseFormatted.api = "sources_list";

    finalResponse.push(responseFormatted);
  }
  console.log(`Going here`);
  return finalResponse;
};

// export const listShipmentsMap = async (user_id, token) => {
//   try {
//     const response = await fetch(apiBase + "shipments/list_all", {
//       method: "POST",
//       body: new URLSearchParams({
//         user_id: user_id,
//         app_token: token,
//       }),
//     });
//     return response.status === 200 ? await response.json() : false;
//   } catch (error) {
//     return { status: 0, error: error };
//   }
// };

export const addNewShipment = async (moduleNames, displayName, user_id, token) => {
  try {
    const response = await fetch(apiBase + "shipments/", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        module_name: moduleNames,
        display_name: displayName,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const closeShipment = async (shipmentId, user_id, token) => {
  try {
    const response = await fetch(apiBase + `shipments/close/${shipmentId}`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        shipment_id: shipmentId,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const updateActiveSourceApi = async (user_id, token, newSource) => {
  try {
    const response = await fetch(apiBase + `marketplace/update_active`, {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        source_name: newSource,
      }),
    });
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const getTitleSearchResults = async (querytext, user_id, token) => {
  try {
    console.log(`Getting title`);
    const response = await fetch(apiBase + "title/search", {
      method: "POST",
      body: new URLSearchParams({
        user_id: user_id,
        app_token: token,
        query: querytext,
      }),
    });
    console.log(response);
    return response.status === 200 ? await response.json() : false;
  } catch (error) {
    return { status: 0, error: error };
  }
};

export const isImportantClick = (targetElement) => {
  console.log(`Clicked: ${targetElement.className}`);
  if (
    targetElement.className !== "" &&
    targetElement.className !== "nav-link" &&
    targetElement.className !== "form-control" &&
    targetElement.className !== "form-control focus-visible" &&
    targetElement.className !== "infoBoxes" &&
    targetElement.className !== "infoValue bsinfo" &&
    targetElement.className !== "btn btn-primary" &&
    targetElement.className !== "infoHeading" &&
    targetElement.className !== "infoValue bsinfo" &&
    targetElement.className !== "infoValue" &&
    targetElement.className !== "infoBoxes" &&
    targetElement.className !== "infoBoxesNull" &&
    targetElement.className !== "infoBoxes" &&
    targetElement.className !== "button-search  btn btn-primary" &&
    targetElement.className !== "text_batchsettings form-control" &&
    targetElement.className !== "inputboxBatchSettings form-control" &&
    targetElement.className !== "mp-source form-control" &&
    targetElement.className !== "fa fa-info-circle "
  ) {
    return true;
  } else {
    return false;
  }
};

export const getASINWinner = async (listingProfile, asintoSearch, isLiveLoad, token, amzRestrictedMessage) => {
  // console.log(`amzRestrictedMessage: ${amzRestrictedMessage}`);
  try {
    const isSBYBCondition = window.location.hostname === "app.myemptyshelves.com" ? 1 : 0;

    const response = await API.get(
      "isbn/" +
        asintoSearch +
        "/" +
        isLiveLoad +
        "?token=" +
        token +
        "&listing_profile=" +
        listingProfile +
        "&is_sbyb=" +
        isSBYBCondition +
        (undefined !== amzRestrictedMessage ? `&rejected_message=` + amzRestrictedMessage : ``)
    );

    return response.data;

    // console.log(
    //   apiBase +
    //     "isbn/" +
    //     asintoSearch +
    //     "/" +
    //     isLiveLoad +
    //     "?token=" +
    //     token +
    //     "&listing_profile=" +
    //     listingProfile +
    //     "&is_sbyb=" +
    //     isSBYBCondition
    // );
    // console.log(apiResponse);
    // return apiResponse;
  } catch (error) {
    if (error.response) return error.response.data;
    else return { status: 0, error: error };
  }
};

// export const getSounds = async () => {
//   const response = await fetch(apiBase + "audio/list");
//   if (response.status === 200) {
//     const data = await response.json();
//     console.log(data);
//     return data;
//   } else return false;
// };

export const sendUpdateWinner = async (
  user_data,
  logID,
  asin,
  productGroup,
  searchText,
  idType,
  moduleWinner,
  subModuleWinner
) => {
  const response = await fetch(apiBase + "user/update_log", {
    method: "POST",
    body: new URLSearchParams({
      user_id: user_data.user_id,
      app_token: user_data.token,
      log_id: logID,
      module_winner: moduleWinner,
      submodule_winner: subModuleWinner,
      asin: asin,
      productGroup: productGroup,
      search_text: searchText,
      id_type: idType,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
